<template>
  <div>
    <b-form-group
      class="mr-1"
      label="Medya Tipi"
      label-for="mediaType"
    >
      <div class="d-flex">
        <b-form-checkbox
          v-model="mediaType"
          class="mr-2"
          value="image"
          switch
          :disabled="mediaType === 'image'"
        >
          Görsel
        </b-form-checkbox>
        <b-form-checkbox
          id="video_slide"
          v-model="mediaType"
          value="video"
          switch
          :disabled="mediaType === 'video'"
        >
          Video
        </b-form-checkbox>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'ButtonLabel',
  components: { BFormGroup, BFormCheckbox },
  props: {
    changeMediaType: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mediaType: 'image',
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['carousel/dataItem']
    },
  },
  watch: {
    mediaType(val, old) {
      if (val) {
        this.changeMediaType(val)
      } else {
        this.mediaType = old
        this.changeMediaType(val)
      }
    },
  },
}
</script>
