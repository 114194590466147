<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <title-text />
        </b-col>
        <b-col
          cols="12"
        >
          <sub-title-text />
        </b-col>
        <b-col
          cols="12"
        >
          <content-text />
        </b-col>
        <b-col cols="4">
          <button-label />
        </b-col>
        <b-col cols="4">
          <button-url />
        </b-col>
        <b-col
          cols="4"
        >
          <ordering-number />
        </b-col>
        <b-col cols="12">
          <media-select :change-media-type="changeMediaType" />
        </b-col>
      </b-row>
    </b-card>
    <template v-if="mediaType === 'image'">
      <image-card />
    </template>
    <template v-if="mediaType === 'video'">
      <video-card />
    </template>
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Carousel/elements/TitleText.vue'
import SubTitleText from '@/views/Admin/Carousel/elements/SubTitleText.vue'
import ContentText from '@/views/Admin/Carousel/elements/ContentText.vue'
import ButtonLabel from '@/views/Admin/Carousel/elements/ButtonLabel.vue'
import ButtonUrl from '@/views/Admin/Carousel/elements/ButtonUrl.vue'
import OrderingNumber from '@/views/Admin/Carousel/elements/OrderingNumber.vue'
import MediaSelect from '@/views/Admin/Carousel/elements/MediaSelect.vue'
import ImageCard from '@/views/Admin/Carousel/elements/ImageCard.vue'
import VideoCard from '@/views/Admin/Carousel/elements/VideoCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    TitleText,
    SubTitleText,
    ContentText,
    ButtonLabel,
    ButtonUrl,
    OrderingNumber,
    MediaSelect,
    ImageCard,
    VideoCard,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mediaType: 'image',
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['carousel/dataItem']
    },
  },
  methods: {
    changeMediaType(type) {
      if (type === 'video') {
        this.itemData.image = null
        this.itemData.image_mobile = null
      }
      if (type === 'image') {
        this.itemData.video = null
        this.itemData.video_mobile = null
      }
      if (this.itemData.id) {
        this.$store.dispatch('carousel/getDataItem', this.itemData.id)
      }
      this.mediaType = type
    },
  },
}
</script>
