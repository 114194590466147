<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card title="Masaüstü Video">
          <pre-loading v-if="uploading" />
          <template v-else>
            <b-embed
              v-if="itemData.video"
              type="iframe"
              aspect="16by9"
              :src="baseURL + '/media/carousel/' + itemData.video"
              allowfullscreen
            />
            <b-form-file
              id="desktop-video"
              v-model="desktop_video"
              class="mt-1"
              placeholder="Bir dosya seçin veya buraya bırakın..."
              drop-placeholder="Dosyayı buraya bırakın..."
              browse-text="Dosya Seçin"
            />
            <div class="mt-1">
              <b-button
                variant="primary"
                :disabled="!desktop_video"
                @click="uploadFile('desktop')"
              >
                <FeatherIcon icon="UploadIcon" />
                Video Yükle
              </b-button>
            </div>
          </template>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card title="Mobil Video">
          <pre-loading v-if="uploading" />
          <b-embed
            v-if="itemData.video_mobile"
            type="iframe"
            aspect="4by3"
            :src="baseURL + '/media/carousel/' + itemData.video_mobile"
            allowfullscreen
          />
          <b-form-file
            id="desktop-video"
            v-model="mobile_video"
            class="mt-1"
            placeholder="Bir dosya seçin veya buraya bırakın..."
            drop-placeholder="Dosyayı buraya bırakın..."
            browse-text="Dosya Seçin"
          />
          <div class="mt-1">
            <b-button
              variant="primary"
              :disabled="!mobile_video"
              @click="uploadFile('mobile')"
            >
              <FeatherIcon icon="UploadIcon" />
              Video Yükle
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BRow, BFormFile, BEmbed,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'VideoCard',
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BEmbed,
    PreLoading,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      desktop_video: null,
      mobile_video: null,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['carousel/dataItem']
    },
    uploading() {
      return this.$store.getters['fileUpload/uploading']
    },
  },
  methods: {
    uploadFile(type) {
      let file = ''
      if (type === 'desktop') {
        file = this.desktop_video
      }
      if (type === 'mobile') {
        file = this.mobile_video
      }
      this.$store.dispatch('fileUpload/uploadFile', {
        upload_file: file,
        folder: 'carousel',
      })
        .then(response => {
          if (response.filename) {
            if (type === 'desktop') {
              this.itemData.video = response.filename
            }
            if (type === 'mobile') {
              this.itemData.video_mobile = response.filename
            }
          }
        })
    },
  },
}
</script>
